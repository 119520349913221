import React from "react";
import { Route, HashRouter } from "react-router-dom";
import Header from "./components/Header/Header.jsx";
import ThumbnailList from "./components/ThumbnailList/ThumbnailList.jsx";
import ChannelTrailer from "./pages/ChannelTrailer.jsx";
import Berzerk from "./pages/Berzerk.jsx";
import FrankLenz from "./pages/FrankLenz.jsx";
import Footer from "./components/Footer/Footer.jsx";
import "./App.css";

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Header />
        <div className="content">
          <Route exact path="/" component={ThumbnailList} />
          <Route path="/sleuth-tv-channel-trailer" component={ChannelTrailer} />
          <Route
            path="/berzerk-arcade-game-the-curse-of-evil-otto"
            component={Berzerk}
          />
          <Route
            path="/frank-lenz-the-cyclist-who-vanished"
            component={FrankLenz}
          />
        </div>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
