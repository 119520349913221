import React from "react";
import S from "./letters/S.gif";
import L from "./letters/L.gif";
import E from "./letters/E.gif";
import U from "./letters/U.gif";
import T from "./letters/T.gif";
import H from "./letters/H.gif";
import V from "./letters/V.gif";
import facebook from "./icons/facebook.svg";
import twitter from "./icons/twitter.svg";
import youtube from "./icons/youtube.svg";
import deviantart from "./icons/deviantart.svg";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img src={S} className="logo-letter" alt="S" />
          <img src={L} className="logo-letter" alt="L" />
          <img src={E} className="logo-letter" alt="E" />
          <img src={U} className="logo-letter" alt="U" />
          <img src={T} className="logo-letter" alt="T" />
          <img src={H} className="logo-letter" alt="H" />
          <div className="tv">
            <img src={T} className="logo-letter" alt="T" />
            <img src={V} className="logo-letter" alt="V" />
          </div>
        </a>
      </div>
      <div className="icon-container">
        <a
          className="custom-icon"
          href="https://www.facebook.com/sleuthtv"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} alt="Button to view Sleuth TV on Facebook" />
        </a>
        <a
          className="custom-icon"
          href="https://www.twitter.com/sleuthtv"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitter} alt="Button to view Sleuth TV on Twitter" />
        </a>
        <a
          className="custom-icon"
          href="https://www.youtube.com/channel/UC2RZY3tl8gnEY6WCdoIHhvw"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={youtube} alt="Button to view Sleuth TV on YouTube" />
        </a>
        <a
          className="custom-icon"
          href="https://www.deviantart.com/sleuthtv"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={deviantart} alt="Button to view Sleuth TV on DeviantArt" />
        </a>
      </div>
    </header>
  );
};

export default Header;
