import React from "react";
import ReactPlayer from "react-player/youtube";
import "./pages.css";

const ChannelTrailer = () => {
  return (
    <div className="page-content">
      <ReactPlayer
        className="video"
        controls="true"
        width="96vmin"
        height="54vmin"
        url="https://www.youtube.com/watch?v=Qkgi2CtyDx8"
      />
      <article className="video-description">
        <p>
          Welcome to Sleuth TV, a channel dedicated to solving the greatest
          mysteries of all time. You can expect documentary videos that blend
          education and entertainment while exploring topics in science,
          history, technology, and folklore. In-depth research is used to
          uncover details and conclusions are deduced from the presented facts.
        </p>
      </article>
    </div>
  );
};

export default ChannelTrailer;
