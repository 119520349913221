import React from "react";
import "./References.css";

const References = (props) => {
  const references = [];
  for (let i = 0; i < props.linkText.length; i++) {
    references.push(
      <li>
        <span className="reference-link">
          <a target="_blank" rel="noopener noreferrer" href={props.links[i]}>
            {props.linkText[i]}
          </a>
        </span>
        <span className="reference-data">{props.referenceData[i]}</span>
      </li>
    );
  }
  return (
    <div className="references-container">
      <h2>Investigation References</h2>
      <ol className="references">{references}</ol>
    </div>
  );
};

export default References;
