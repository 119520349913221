import React from "react";
import "./Credits.css";

const Credits = (props) => {
  const credits = [];
  let creditsClass = "credits";
  if (props.singleColumn === true) {
    creditsClass = "credits-single";
  }
  for (let i = 0; i < props.linkText.length; i++) {
    if (props.linkText[i] === "") {
      credits.push(<li className="hidden"></li>);
      continue;
    }
    if (props.linkText[i] === "Audio" || props.linkText[i] === "Video") {
      credits.push(
        <li className="list-title">
          <h3>{props.linkText[i]}</h3>
        </li>
      );
      continue;
    }
    if (props.license[i] === "") {
      credits.push(
        <li>
          <span className="credit-link">
            <a target="_blank" rel="noopener noreferrer" href={props.links[i]}>
              {props.linkText[i]}
            </a>
          </span>
          <span>{props.creditUser[i]}</span>
        </li>
      );
      continue;
    }
    credits.push(
      <li>
        <span className="credit-link">
          <a target="_blank" rel="noopener noreferrer" href={props.links[i]}>
            {props.linkText[i]}
          </a>
        </span>
        <span>{props.creditUser[i]}</span>
        <span className="credit-link">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.licenseLinks[i]}
          >
            {props.license[i]}
          </a>
        </span>
      </li>
    );
  }
  return (
    <div className="credits-container">
      <h2>Credits</h2>
      <ol className={creditsClass}>{credits}</ol>
    </div>
  );
};

export default Credits;
