import React from "react";
import ReactPlayer from "react-player/youtube";
import Credits from "../components/Credits/Credits.jsx";
import References from "../components/References/References.jsx";
import "./pages.css";

const Berzerk = () => {
  return (
    <div className="page-content">
      <ReactPlayer
        className="video"
        controls="true"
        width="96vmin"
        height="54vmin"
        url="https://www.youtube.com/watch?v=dGt3Ei_KJKk"
      />
      <article className="video-description">
        <p>
          Every day, lives are lost while playing video games; virtual lives
          though, not real ones... right?
        </p>
        <p>
          Welcome to the first episode of Game Sleuth, a series where we analyze
          the greatest mysteries involving video games. In this episode, I will
          be investigating the deaths surrounding an arcade game from the 80s
          called Berzerk, and the curse that many claim caused them. Is this
          game really the first game in history to result in the death of a
          player? Stay-tuned as we take a look back to the golden age of arcade
          games and explore the facts surrounding Berzerk as we attempt to solve
          this mystery.
        </p>
      </article>
      <References
        linkText={[
          "Virginia, Death Certificates, 1912-1987",
          "Newspapers.com: Chicago Tribune",
          "Youth Charged in Stabbing Death",
          "Game Over: Popular Calumet City Arcade Closing After 21 Years",
          "Berzerk",
          "“Kill the Humanoid!”",
        ]}
        links={[
          "https://www.familysearch.org/ark:/61903/1:1:QVYG-3SDN",
          "https://www.newspapers.com/newspage/387934718/",
          "https://www.chicagotribune.com/news/ct-xpm-1988-03-22-8803020534-story.html",
          "https://www.nwitimes.com/news/local/game-over/article_2ff8f1be-e4b7-5071-ab58-d7272c89b252.html",
          "https://www.arcade-museum.com/game_detail.php?game_id=7096",
          "https://www.retrogamer.net/",
        ]}
        referenceData={[
          "Aug 20, 2018 | FamilySearch | Virginia Department of Health",
          "Apr 27, 1982 | Page 3",
          "Mar 22, 1988",
          "May 7, 2003 | Lauri Harvey | Times Staff Writer",
          "International Arcade Museum® | Killer List of Videogames® | Museum of the Game®",
          "Sidebar | Issue 47 | Retro Gamer Magazine",
        ]}
      ></References>
      <Credits
        linkText={[
          "Video",
          "Berzerk Emulation Footage",
          "Stock Footage",
          "Smiley Tower Photo",
          "Outro Template",
          "Audio",
          "Suspense and Mystery",
          "Suspence Crime Scene",
          "Mini Emotional Ambient",
          "",
        ]}
        links={[
          "",
          "https://www.youtube.com/watch?v=Wl9K4btHT28&t=4s",
          "https://www.videvo.net/",
          "https://www.flickr.com/photos/davidwilson1949/9249492781/in/photolist-SxGrsJ-f6m4Xt",
          "https://www.youtube.com/channel/UCEJTprqcXtKcHeTGJ7Qjfvg/search?query=template",
          "",
          "https://freesound.org/people/tyops/sounds/387528/",
          "https://freesound.org/people/tyops/sounds/402273/",
          "https://freesound.org/people/tyops/sounds/443086/",
          "",
        ]}
        creditUser={[
          "",
          "busted_hinge",
          "Videvo",
          "David Wilson",
          "Grabster",
          "",
          "Tyops",
          "Tyops",
          "Tyops",
          "",
        ]}
        license={[
          "",
          "",
          "",
          "Attribution 2.0 Generic License",
          "",
          "",
          "Attribution 4.0 International License",
          "Attribution 4.0 International License",
          "Attribution 4.0 International License",
          "",
        ]}
        licenseLinks={[
          "",
          "",
          "",
          "https://creativecommons.org/licenses/by/2.0/legalcode",
          "",
          "",
          "https://creativecommons.org/licenses/by/4.0/legalcode",
          "https://creativecommons.org/licenses/by/4.0/legalcode",
          "https://creativecommons.org/licenses/by/4.0/legalcode",
          "",
        ]}
      ></Credits>
    </div>
  );
};

export default Berzerk;
