import React from "react";
import ReactPlayer from "react-player/youtube";
import Credits from "../components/Credits/Credits.jsx";
import References from "../components/References/References.jsx";
import "./pages.css";

const FrankLenz = () => {
  return (
    <div className="page-content">
      <ReactPlayer
        className="video"
        controls="true"
        width="96vmin"
        height="54vmin"
        url="https://www.youtube.com/watch?v=6HyiHkc5I-s"
      />
      <article className="video-description">
        <p>
          Welcome to the first episode of History Sleuth, a series where we
          travel back in time to solve mysteries that have often been forgotten
          or lost in the flow of time and uncover new details that were
          originally overlooked. In today’s episode we will be looking at the
          life of Frank Lenz, an American who set out to circle the globe via
          bicycle in 1892, but vanished along the way. Tune in to learn about
          his incredible journey as we uncover the facts of his mysterious
          disappearance.
        </p>
      </article>
      <References
        linkText={[
          "The Lost Cyclist",
          "Frank Lenz (Cyclist)",
          "The Unsolved Case of the “Lost Cyclist”",
          "The Cyclist Who Disappeared on His Mission to Circumnavigate the Globe",
        ]}
        links={[
          "https://www.amazon.com/Lost-Cyclist-Adventurer-Mysterious-Disappearance/dp/B00DO0SWNU",
          "https://en.wikipedia.org/wiki/Frank_Lenz_(cyclist)#:~:text=Frank%20George%20Lenz%20(born%201867,circle%20the%20globe%20by%20bicycle",
          "https://www.smithsonianmag.com/history/the-unsolved-case-of-the-lost-cyclist-57021309/",
          "https://www.thevintagenews.com/2018/10/31/frank-lenz/",
        ]}
        referenceData={[
          "2010 | David V. Herlihy",
          "Wikipedia",
          "Aug 26, 2010 | Megan Gambino | Smithsonian Magazine",
          "Oct 31, 2018 | Rachel Kester | The Vintage News",
        ]}
      ></References>
      <Credits
        singleColumn={true}
        linkText={[
          "Armenian Massacre Artwork",
          "Alexander Watkins Terrell Photo",
          "Allen and Sachtleben Photo",
          "Frank Lenz in Missoula, Mont., 1892",
          "Various Frank Lenz Photos",
          "Jungle Pathway Footage",
          "Outro Template",
          "Documentary Ambient Sound",
        ]}
        links={[
          "https://www.amazon.com/Bleeding-Armenia-Rev-W-Williams/dp/1517121132",
          "https://repository.duke.edu/dc/strong",
          "https://www.smithsonianmag.com/history/the-unsolved-case-of-the-lost-cyclist-57021309/",
          "https://www.lib.umt.edu/",
          "https://www.smithsonianmag.com/history/the-unsolved-case-of-the-lost-cyclist-57021309/",
          "https://www.videvo.net/",
          "https://www.youtube.com/GrabsterTV",
          "https://www.youtube.com/watch?v=sKEuw1fEep0",
        ]}
        creditUser={[
          "Williams, A[ugustus] W[arner] | Bleeding Armenia | [Chicago] Publishers' Union | 1896",
          "Duke Libraries | William Emerson Strong | Photograph Album Circa 1860s",
          "Bearings Magazine",
          "William A. Hoblitzell | Courtesy of Archives and Special Collections | Mansfield Library | University of Montana",
          "Smithsonian Magazine | Courtesy of David V. Herlihy",
          "Videvo & Kiril Dobrev",
          "Grabster",
          "Tyops",
        ]}
        license={[
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Attribution 4.0 International License",
        ]}
        licenseLinks={[
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "https://creativecommons.org/licenses/by/4.0/legalcode",
        ]}
      ></Credits>
    </div>
  );
};

export default FrankLenz;
