import React from "react";
import { NavLink } from "react-router-dom";
import frank from "./thumbnails/frank.JPEG";
import berzerk from "./thumbnails/berzerk.JPEG";
import trailer from "./thumbnails/trailer.JPEG";
import "./ThumbnailList.css";

const ThumbnailList = () => {
  return (
    <section className="thumbnail-list">
      <NavLink to="/frank-lenz-the-cyclist-who-vanished" className="darken">
        <img
          className="thumbnail"
          src={frank}
          alt="Link to view Frank Lenz video"
        ></img>
        <span>Frank Lenz: The Cyclist Who Vanished</span>
      </NavLink>
      <NavLink
        to="/berzerk-arcade-game-the-curse-of-evil-otto"
        className="darken"
      >
        <img
          className="thumbnail"
          src={berzerk}
          alt="Link to view Berzerk Arcade Game video"
        ></img>
        <span>Berzerk Arcade Game: The Curse of Evil Otto</span>
      </NavLink>
      <NavLink to="/sleuth-tv-channel-trailer" className="darken">
        <img
          className="thumbnail"
          src={trailer}
          alt="Link to view Sleuth TV Channel Trailer"
        ></img>
        <span>Sleuth TV: Channel Trailer</span>
      </NavLink>
    </section>
  );
};

export default ThumbnailList;
